import React, { useEffect, useRef, useState } from "react";

import { graphql } from "gatsby";
import { motion } from "framer-motion";
import HTMLReactParser from "html-react-parser";

import { HomeContainer } from "components/common/Structure";
import Footer from "components/footer";
import { StyledLink } from "components/common/Links";
import ReactPlayer from "react-player/file";

const Homepage = (props) => {
  //PROPS
  const {
    data: {
      page: {
        homepage: { textIntro, videoIntro, videoIntroMobile, videoIntroTablet },
      },
    },
    setColor,
    dimensions,
    location: { pathname },
  } = props;
  //PROPS

  //HOMEANIM
  const homeWrapper = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.5, duration: 1 },
    },
    exit: {
      opacity: 0,
      transition: { ease: "easeInOut", duration: 1 },
    },
  };
  //HOMEANIM

  //USESTATE
  const [video, setVideo] = useState({
    playing: true,
    controls: true,
    volume: 0.2,
    muted: true,
    duration: 0,
    autoplay: false,
    withSound: false,
    played: 0,
    ready: false,
  });
  //USESTATE

  //REF
  const thePlayer = useRef(null);
  //REF

  //USEEFFECTS
  useEffect(() => {
    setColor(false);
  }, []);

  useEffect(() => {
    setVideo({
      ...video,
      playing: true,
    });
  }, []);
  //USEEFFECTS

  const lang =
    props.pageContext.translations[0].language.code === "EN" ? "PT" : "EN";

  return (
    <>
      <motion.div
        variants={homeWrapper}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <HomeContainer>
          {dimensions.width && (
            <ReactPlayer
              playsinline={true}
              className="react-player"
              url={
                dimensions.width >= 769
                  ? videoIntro.mediaItemUrl
                  : dimensions.width >= 450
                  ? videoIntroTablet.mediaItemUrl
                  : videoIntroMobile.mediaItemUrl
              }
              width="100%"
              height="100%"
              //
              onReady={() => setVideo({ ...video, ready: true })}
              onPause={() => setVideo({ ...video, playing: false })}
              //
              loop={true}
              controls={false}
              playing={video.playing}
              light={video.light}
              muted={video.muted}
              ref={thePlayer}
            />
          )}
          <div className="container">
            <div className="row">
              <span id="title">{textIntro && HTMLReactParser(textIntro)}</span>
            </div>
            <div className="row">
              {pathname === "/" ? (
                <StyledLink to="/quem-somos">Saber Mais</StyledLink>
              ) : (
                <StyledLink to="/en/who-we-are">Learn More</StyledLink>
              )}
            </div>
          </div>
          <Footer isHome={true} lang={lang} />
        </HomeContainer>
      </motion.div>
    </>
  );
};

export const query = graphql`
  query Home($id: String) {
    page: wpPage(id: { eq: $id }) {
      homepage {
        textIntro
        linkIntro {
          target
          title
          url
        }
        videoIntroMobile {
          mediaItemUrl
        }
        videoIntroTablet {
          mediaItemUrl
        }
        videoIntro {
          mediaItemUrl
        }
      }
    }
  }
`;

export default Homepage;
